@import "./src/_metronic/assets/sass/core/components/variables";
//.metro-table {
//  font-size: 12px;
//  color: #666;
//  transition: opacity 0.3s ease;
//  position: relative;
//  line-height: 1.5;
//  overflow: hidden;
//}

.metro-table {
  position: relative;
  box-sizing: border-box;
  color: #666;
  font-size: 13px;
  line-height: 1.5;
  table {
    border-collapse: separate;
    width: 100%;
    border-spacing: 0px;
  }
  thead {
    th {
      text-align: center;
    }
    .metro-table-cell-scrollbar {
      &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1px;
        width: 1px;
        background: #f7f7f7;
        content: '';
      }
    }
    th.metro-table-cell-d-none {
      display: none;
    }
    th.metro-table-cell-ellipsis, th.metro-table-thead-cell-ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .metro-table-cell-filter {
        .metro-table-cell-title-with-filter {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .metro-table-cell-title-with-filter {
      width: 100%;
    }
  }
  tfoot {
    td {
      background: #fff;
    }
  }
}

.metro-table .metro-table-scroll {
  overflow: auto;
}
.metro-table .metro-table-scroll table {
  width: auto;
  min-width: 100%;
}
.metro-table thead td,
.metro-table thead th {
  border-bottom-width: 2px;
  //text-align: left;
  text-align: center;
  font-weight: bold;
  color: $gray-500-dark;
  background: #fff;
}
tr.metro-table-expanded-row {
  background: #f7f7f7;

  .metro-table-cell {
    background: inherit !important;
  }
}
tr.metro-table-expanded-row:hover {
  background: #f7f7f7;
}
.metro-table tbody tr td,
.metro-table tbody tr th {
  background: #fff;
}
.metro-table .metro-table-header {
  overflow: hidden;
  background: #f7f7f7;
}
.metro-table-fixed-header .metro-table-body {
  background: #fff;
  position: relative;
}
.metro-table-fixed-header .metro-table-body-inner {
  height: 100%;
  overflow: scroll;
}
.metro-table-fixed-header .metro-table-scroll .metro-table-header {
  padding-bottom: 20px;
  margin-bottom: -20px;
  overflow: scroll;
  box-sizing: border-box;
}
//.metro-table-hide-scrollbar {
//  scrollbar-color: transparent transparent;
//}
//.metro-table-hide-scrollbar::-webkit-scrollbar {
//  background-color: transparent;
//}
.metro-table-fixed-columns-in-body {
  visibility: hidden;
  pointer-events: none;
}
.metro-table .metro-table-title {
  padding: 16px 8px;
  border-top: 1px solid #e9e9e9;
}
.metro-table .metro-table-content {
  //position: relative; //default setting
  position: static;
}
.metro-table .metro-table-footer {
  padding: 16px 8px;
  border-bottom: 1px solid #e9e9e9;
}
.metro-table .metro-table-placeholder {
  padding: 16px 8px;
  background: #fff;
  border-bottom: 1px solid #e9e9e9;
  text-align: center;
  position: relative;
}
.metro-table .metro-table-placeholder-fixed-columns {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: transparent;
  pointer-events: none;
}
.metro-table table {
  width: 100%;
  //border-collapse: collapse;
  text-align: left;
}
.metro-table th {
  background: #f7f7f7;
  font-weight: bold;
  transition: background 0.3s ease;
}
.metro-table td {
  border-bottom: 1px solid $gray-200;
}
.metro-table td:empty:after {
  content: ".";
  visibility: hidden;
}
.metro-table tr {
  transition: all 0.3s ease;
}
.metro-table tr:hover {
  background: #eaf8fe;
}
.metro-table tr.metro-table-row-hover {
  background: #eaf8fe;
}
.metro-table th,
.metro-table td {
  padding: 12px 8px;
  white-space: nowrap;
}
.metro-table-expand-icon-col {
  width: 34px;
}
.metro-table-expanded-row > td.metro-table-cell {
  //box-shadow: inset 0 8px 8px -8px green;
  box-shadow: inset 0 8px 8px -8px #646464;
}
.metro-table-row-expand-icon,
.metro-table-expanded-row-expand-icon {
  cursor: pointer;
  display: inline-block;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  border: 1px solid #e9e9e9;
  user-select: none;
  background: #fff;
}
.metro-table-row-spaced,
.metro-table-expanded-row-spaced {
  visibility: hidden;
}
.metro-table-row-spaced:after,
.metro-table-expanded-row-spaced:after {
  content: ".";
}
.metro-table-row-expanded:after,
.metro-table-expanded-row-expanded:after {
  content: "-";
}
.metro-table-row-collapsed:after,
.metro-table-expanded-row-collapsed:after {
  content: "+";
}
.metro-table-column-hidden {
  display: none;
}
.metro-table-prev-columns-page,
.metro-table-next-columns-page {
  cursor: pointer;
  color: #666;
  z-index: 1;
}
.metro-table-prev-columns-page:hover,
.metro-table-next-columns-page:hover {
  color: #2db7f5;
}
.metro-table-prev-columns-page-disabled,
.metro-table-next-columns-page-disabled {
  cursor: not-allowed;
  color: #999;
}
.metro-table-prev-columns-page-disabled:hover,
.metro-table-next-columns-page-disabled:hover {
  color: #999;
}
.metro-table-prev-columns-page {
  margin-right: 8px;
}
.metro-table-prev-columns-page:before {
  content: "<";
}
.metro-table-next-columns-page {
  float: right;
}
.metro-table-next-columns-page:before {
  content: ">";
}
.metro-table-fixed-left,
.metro-table-fixed-right {
  position: absolute;
  top: 0;
  overflow: hidden;
}
.metro-table-fixed-left table,
.metro-table-fixed-right table {
  width: auto;
  background: #fff;
}
.metro-table-fixed-left {
  left: 0;
  box-shadow: 4px 0 4px rgba(100, 100, 100, 0.1);
}
.metro-table-fixed-left .metro-table-body-inner {
  margin-right: -20px;
  padding-right: 20px;
}
.metro-table-fixed-header .metro-table-fixed-left .metro-table-body-inner {
  padding-right: 0;
}
.metro-table-fixed-right {
  right: 0;
  box-shadow: -4px 0 4px rgba(100, 100, 100, 0.1);
}
.metro-table-fixed-right .metro-table-expanded-row {
  color: transparent;
  pointer-events: none;
}
.metro-table.metro-table-scroll-position-left .metro-table-fixed-left {
  box-shadow: none;
}
.metro-table.metro-table-scroll-position-right .metro-table-fixed-right {
  box-shadow: none;
}
.metro-table-cell {
  &.metro-table-cell-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &.metro-table-cell-row-hover {
    //background: rgba(255,0,0,0.05);
    //background: rgba(0,0,0,0.04) !important;
    background: #ededed !important;
    //z-index: 1;
  }
}
.metro-table-row-cell-break-word {
  word-wrap: break-word;
  word-break: break-word;
}
.metro-table-layout-fixed table {
  table-layout: fixed;
}


.metro-table-rtl {
  direction: rtl;
  .metro-table-cell-fix-right {
    &:last-child {
      border-right-color: red;
    }
  }
  .metro-table-cell-fix-left {
    &:last-child {
      border-left-color: transparent;
    }
  }
  .metro-table-cell-fix-left-first {
    box-shadow: 1px 0 0 red;
  }
  &.metro-table {
    thead {
      .metro-table-cell-scrollbar {
        &::after {
          right: -1px;
          left: auto;
        }
      }
    }
  }
}

.metro-table-cell-fix-left,
.metro-table-cell-fix-right {
  z-index: 1;
}
.metro-table-cell-fix-right {
  &:last-child {
    &:not(.metro-table-cell-fix-sticky) {
      border-right-color: transparent;
    }
  }
}
.metro-table-cell-fix-left-first::after,
.metro-table-cell-fix-left-last::after {
  position: absolute;
  top: 0;
  right: -1px;
  bottom: -1px;
  width: 20px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.metro-table-cell-fix-right-first,
.metro-table-cell-fix-right-last {
  //box-shadow: -1px 0 0 red;
}
.metro-table-rtl .metro-table-cell-fix-right-first,
.metro-table-rtl .metro-table-cell-fix-right-last {
  box-shadow: none;
}
.metro-table-cell-fix-right-first::after,
.metro-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: -1px;
  width: 20px;
  transform: translateX(-100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}

.metro-table-cell.metro-table-cell-ellipsis.metro-table-cell-fix-left-first,
.metro-table-cell.metro-table-cell-ellipsis.metro-table-cell-fix-left-last,
.metro-table-cell.metro-table-cell-ellipsis.metro-table-cell-fix-right-first .metro-table-cell.metro-table-cell-ellipsis.metro-table-cell-fix-right-last {
  overflow: visible;
}
.metro-table-cell.metro-table-cell-ellipsis.metro-table-cell-fix-left-first .metro-table-cell-content,
.metro-table-cell.metro-table-cell-ellipsis.metro-table-cell-fix-left-last .metro-table-cell-content,
.metro-table-cell.metro-table-cell-ellipsis.metro-table-cell-fix-right-first .metro-table-cell.metro-table-cell-ellipsis.metro-table-cell-fix-right-last .metro-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.metro-table-ping-left .metro-table-cell-fix-left-first::after,
.metro-table-ping-left .metro-table-cell-fix-left-last::after {
  //box-shadow: inset 10px 0 8px -8px $nashta;
  box-shadow: inset 12px 0 8px -8px #b1b1b180
}
.metro-table-ping-right .metro-table-cell-fix-right-first::after,
.metro-table-ping-right .metro-table-cell-fix-right-last::after {
  //box-shadow: inset -10px 0 8px -8px $nashta;
  box-shadow: inset -12px 0 8px -8px #b1b1b180
}
