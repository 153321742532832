//
// Theme style
//

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "./core/layout/base";
@import "layout/layout";

.form-select2__placeholder {
  color: $input-solid-placeholder-color !important;
}
.form-select2__container {
  background-image: none !important;
}
.form-select2__menu {
  min-width: 250px !important;
  z-index: 2111 !important;
}
.form-select2__control {
  box-shadow: none !important;
  border-color: unset !important;
  background: none !important;
  border-style: none !important;
  //min-height: 3.45rem !important;
  min-height: auto;
}
.form-select2-lg {
  .form-select2__control {
    min-height: 3.373rem !important;
  }
}
.form-select2-sm {
  min-height: calc(1.5em + 0.838rem + 2px);
  .form-select2__control {
    min-height: 2.4rem !important;
  }
  .form-select2__placeholder, .form-select2__single-value {
    font-size: 0.925rem;
  }
  .form-select2__input-container {
    margin: 0;
  }
  .form-select2__indicator {
    height: 20px;
    align-items: center;
    display: flex;
  }
}

.form-select2__indicator-separator {
  display: none;
}

.form-select2__input-container, .form-select2__single-value {
  color: $input-solid-color !important;
}

.bb-1 {
  border-bottom-width: 1px !important;
}

.bb-2 {
  border-bottom-width: 2px !important;
}

.bb-3 {
  border-bottom-width: 3px !important;
}

.bb-4 {
  border-bottom-width: 4px !important;
}

.bb-5 {
  border-bottom-width: 5px !important;
}
.bs-solid {
  border-style: solid !important;
}
.b-color-secondary {
  border-color: $secondary;
}
.btn.btn-hover-scale:hover {
  transform: scale(1.1);
  transition: transform .3s ease;
}
.btn.btn-hover-scale {
  transition: transform .3s ease;
}


.react-datepicker__header  {
  background: white !important;
  border-bottom: 0 !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected {
  background-color: $component-hover-color !important;
}

.metro-tabs {
  .disabled {
    cursor: not-allowed !important;
  }
}

.d-inherit {
  display: inherit !important;
}
.z-index-0 {
  z-index: 0 !important;
}

// Start MetroDropdownMenuV2
@font-face {
  font-family: 'anticon';
  src: url('//at.alicdn.com/t/font_1434092639_4910953.eot');
  /* IE9*/
  src: url('//at.alicdn.com/t/font_1434092639_4910953.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//at.alicdn.com/t/font_1434092639_4910953.woff') format('woff'), /* chrome、firefox */ url('//at.alicdn.com/t/font_1434092639_4910953.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/ url('//at.alicdn.com/t/font_1434092639_4910953.svg#iconfont') format('svg');
  /* iOS 4.1- */
}
.rc-dropdown {
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: 1070;
  display: block;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
}
.rc-dropdown-hidden {
  display: none;
}
.rc-dropdown-menu {
  outline: none;
  position: relative;
  //list-style-type: none;
  //padding: 0;
  //margin: 2px 0 2px;
  //text-align: left;
  //background-color: #fff;
  //border-radius: 3px;
  /*box-shadow: 0 1px 5px #ccc;*/
  /*background-clip: padding-box;*/
  /*border: 1px solid #ccc;*/
  //box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 10%);

  //position: absolute;
  z-index: $zindex-dropdown;
  //display: none; // none by default, but block on "open" of the menu
  min-width: $dropdown-min-width;
  padding: $dropdown-padding-y $dropdown-padding-x;
  margin: 0; // Override default margin of ul
  @include font-size($dropdown-font-size);
  color: $dropdown-color;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: $dropdown-bg;
  background-clip: padding-box;
  border: $dropdown-border-width solid $dropdown-border-color;
  @include border-radius($dropdown-border-radius);
  @include box-shadow($dropdown-box-shadow);
}
.rc-dropdown-menu > li {
  margin: 0;
  padding: 0;
}
.rc-dropdown-menu:before {
  content: "";
  position: absolute;
  top: -4px;
  left: 0;
  width: 100%;
  height: 4px;
  background: #ffffff;
  background: rgba(255, 255, 255, 0.01);
}
.rc-dropdown-menu > .rc-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 7px 10px;
  clear: both;
  font-size: 12px;
  font-weight: normal;
  color: #666666;
  white-space: nowrap;
}
.rc-dropdown-menu > .rc-dropdown-menu-item:hover,
.rc-dropdown-menu > .rc-dropdown-menu-item:focus{
  color: $dropdown-link-hover-color;
  text-decoration: if($link-hover-decoration == underline, none, null);
  @include gradient-bg($primary);
}
.rc-dropdown-menu > .rc-dropdown-menu-item-active,
.rc-dropdown-menu > .rc-dropdown-menu-item-selected {
  //background-color: #ebfaff;
  color: $dropdown-link-active-color;
  text-decoration: none;
  @include gradient-bg($dropdown-link-active-bg);
}
.rc-dropdown-menu > .rc-dropdown-menu-item-selected {
  position: relative;
}
.rc-dropdown-menu > .rc-dropdown-menu-item-selected:after {
  content: '\e613';
  font-family: 'anticon';
  font-weight: bold;
  position: absolute;
  top: 6px;
  right: 16px;
  //color: #3CB8F0;
  color: $dropdown-link-active-color;
}
.rc-dropdown-menu > .rc-dropdown-menu-item-disabled {
  color: #ccc;
  cursor: not-allowed;
  pointer-events: none;
}
.rc-dropdown-menu > .rc-dropdown-menu-item-disabled:hover {
  color: #ccc;
  background-color: #fff;
  cursor: not-allowed;
}
.rc-dropdown-menu > .rc-dropdown-menu-item:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.rc-dropdown-menu > .rc-dropdown-menu-item:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.rc-dropdown-menu > .rc-dropdown-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  background-color: #e5e5e5;
  line-height: 0;
}
.rc-dropdown-slide-up-enter,
.rc-dropdown-slide-up-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  display: block !important;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-play-state: paused;
}
.rc-dropdown-slide-up-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  display: block !important;
  opacity: 1;
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-play-state: paused;
}
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomLeft,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomLeft,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomCenter,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomCenter,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomRight,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomRight {
  animation-name: rcDropdownSlideUpIn;
  animation-play-state: running;
}
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topLeft,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topLeft,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topCenter,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topCenter,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topRight,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topRight {
  animation-name: rcDropdownSlideDownIn;
  animation-play-state: running;
}
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomLeft,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomCenter,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomRight {
  animation-name: rcDropdownSlideUpOut;
  animation-play-state: running;
}
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topLeft,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topCenter,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topRight {
  animation-name: rcDropdownSlideDownOut;
  animation-play-state: running;
}
@keyframes rcDropdownSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}
@keyframes rcDropdownSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
}
@keyframes rcDropdownSlideDownIn {
  0% {
    opacity: 0;
    transform-origin: 0% 100%;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 100%;
    transform: scaleY(1);
  }
}
@keyframes rcDropdownSlideDownOut {
  0% {
    opacity: 1;
    transform-origin: 0% 100%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 100%;
    transform: scaleY(0);
  }
}
.rc-dropdown-arrow {
  position: absolute;
  border-width: 4px;
  border-color: transparent;
  box-shadow: 0 1px 5px #ccc;
  border-style: solid;
  transform: rotate(45deg);
}
.rc-dropdown-show-arrow.rc-dropdown-placement-top,
.rc-dropdown-show-arrow.rc-dropdown-placement-topLeft,
.rc-dropdown-show-arrow.rc-dropdown-placement-topRight {
  padding-bottom: 6px;
}
.rc-dropdown-show-arrow.rc-dropdown-placement-bottom,
.rc-dropdown-show-arrow.rc-dropdown-placement-bottomLeft,
.rc-dropdown-show-arrow.rc-dropdown-placement-bottomRight {
  padding-top: 6px;
}
.rc-dropdown-placement-top .rc-dropdown-arrow,
.rc-dropdown-placement-topLeft .rc-dropdown-arrow,
.rc-dropdown-placement-topRight .rc-dropdown-arrow {
  bottom: 4px;
  border-top-color: white;
}
.rc-dropdown-placement-top .rc-dropdown-arrow {
  left: 50%;
}
.rc-dropdown-placement-topLeft .rc-dropdown-arrow {
  left: 15%;
}
.rc-dropdown-placement-topRight .rc-dropdown-arrow {
  right: 15%;
}
.rc-dropdown-placement-bottom .rc-dropdown-arrow,
.rc-dropdown-placement-bottomLeft .rc-dropdown-arrow,
.rc-dropdown-placement-bottomRight .rc-dropdown-arrow {
  top: 4px;
  border-bottom-color: white;
}
.rc-dropdown-placement-bottom .rc-dropdown-arrow {
  left: 50%;
}
.rc-dropdown-placement-bottomLeft .rc-dropdown-arrow {
  left: 15%;
}
.rc-dropdown-placement-bottomRight .rc-dropdown-arrow {
  right: 15%;
}
// End MetroDropdownMenuV2


.btn:disabled {
  cursor: not-allowed;
  pointer-events: auto;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

// Start React Date Picker
.react-datepicker, .react-datepicker-wrapper {
  display: flex;
  justify-content: center;
  border: none !important;

  .react-datepicker__day, .react-datepicker__day-name {
    width: 2.3rem;
  }

  div.react-datepicker__time-container > div.react-datepicker__time .react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
    background-color: $primary !important;
  }
  .react-datepicker__day--selected {
    background-color: $primary;
  }
  .react-datepicker__day--in-range {
    background-color: $primary;
  }
  .react-datepicker__month--selected {
    background-color: $primary;
  }

  .react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: $primary;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "×";
  }
}
// End React Date Picker

.metro-dropdown-v2 {
  outline: none;
  position: relative;
  //list-style-type: none;
  //padding: 0;
  //margin: 2px 0 2px;
  //text-align: left;
  //background-color: #fff;
  //border-radius: 3px;
  /*box-shadow: 0 1px 5px #ccc;*/
  /*background-clip: padding-box;*/
  /*border: 1px solid #ccc;*/
  //box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 10%);

  //position: absolute;
  //z-index: $zindex-modal;
  z-index: 1600;
  //display: none; // none by default, but block on "open" of the menu
  min-width: $dropdown-min-width;
  padding: $dropdown-padding-y $dropdown-padding-x;
  margin: 0; // Override default margin of ul
  @include font-size($dropdown-font-size);
  color: $dropdown-color;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: $dropdown-bg;
  background-clip: padding-box;
  border: $dropdown-border-width solid $dropdown-border-color;
  @include border-radius($dropdown-border-radius);
  @include box-shadow($dropdown-box-shadow);
}

.swal2-container {
  z-index: 10111 !important;
}
.background-none {
  background: none;
}
.border-none {
  border: none;
}

.right-116px {
  right: 116px !important;
}
.left-34px {
  left: 34px !important;
}

.out-ap-header-card-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 9rem;
  z-index: 100;
  background: white !important;
}
.container-mod {
  width: 100% !important;
  padding: 0 2rem !important;
}

.metro-table {
  table {
    @include scrollbar-color(red);
  }
}

// metro-point-{colors}
@each $name, $color in $theme-text-colors {
  // Base color
  .metro-point-#{$name}::before {
    color: $color !important;
    content: "\2022";
    //color: red;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    font-size: initial;
    margin-left: -1em;
  }
}

.tree-menu-container {
  //margin: 12px;
}
.tree-menu-container ul {
  padding-left: 20px;
}

.tree-menu-container ul li {
  list-style-type: none;
  padding-left: 30px;
  position: relative;
  line-height: 2em;
}

.tree-menu-container ul li:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-radius: 0 0 0 0px;
  height: 100%;
}

.tree-menu-container ul li:after {
  content: "";
  display: block;
  position: absolute;
  top: 1em;
  left: 0px;
  border-top: 1px solid #ccc;
  border-radius: 0px 0 0 0;
  width: 20px;
  height: 100%;
}

.tree-menu-container ul li:last-of-type:before {
  height: 1em;
}

.tree-menu-container .drop-target {
  background-color: #e8f0fe;
}

.tree-menu-container .dragging-source {
  opacity: 0.3;
}


.metro-toolbar-custom {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .metro-toolbar-custom {
    display: inline;
    justify-content: normal;
  }
}
@media (min-width: 1400px) {
  .container2 {max-width: 100% !important;}
}
@media (min-width: 1200px) {
  .container2 {max-width: 100% !important;}
}
@media (min-width: 768px) {
  .container2 {max-width: 100% !important;}
}
@media (min-width: 992px) {
  .container2 {max-width: 100% !important;}
}
@media (min-width: 576px) {
  .container2 {max-width: 100% !important;}
}

.text-blue{
  color : #3D9CF2;
}

.outline-dashed{
  outline: dashed 1px #D9D9D9 !important;
}