.metro-text-editor {
  background-color: #f5f8fa;
  border-color: #f5f8fa;
  border-radius: 0.475rem;
  .ql-editor {
    min-height: 150px;
    padding: 0;
  }
  .ql-toolbar.ql-snow {
    border: none;
  }
  .ql-container.ql-snow {
    padding: 0 12px;
    font-size: inherit;
    border: none;
  }
}
